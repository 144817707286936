import axios from 'axios';
import { API_URL } from "gatsby-env-variables"
import { onSuccessRequest, onFailedRequest } from './onRequestInterceptor';
import { onSuccessResponse, onFailedResponse } from './onResponseInterceptor';

const instance = axios.create({
    baseURL: `${API_URL}/`,
});

instance.interceptors.response.use(onSuccessResponse, onFailedResponse);

instance.interceptors.request.use(onSuccessRequest, onFailedRequest);

const user = JSON.parse(typeof window !== 'undefined' && localStorage.getItem('originall-fii-user'));
const access_token =
    user && user.accessToken && user.accessToken !== ''
        ? `Bearer ${user.accessToken}`
        : null;

// Alter defaults after instance has been created
instance.defaults.headers.common['Authorization'] = access_token;
instance.defaults.headers.common['Accept-Language'] = 'en';

export default instance;