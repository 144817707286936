import qs from 'query-string';
import moment from 'moment';
import _ from 'lodash';

const isObject = val => {
  return val instanceof Object;
};

const clearObject = (data, method) => {
  if (Array.isArray(data)) {
    return data.map(item => {
      return clearObject(item);
    });
  } else {
    // deal with moment as a special case...
    // no need to loop inside it
    if (data instanceof moment) {
      return data.toJSON();
    } else {
      Object.keys(data || {}).forEach(key => {
        if (Array.isArray(data[key])) {
          data[key] = clearObject(data[key]);
        } else {
          if (isObject(data[key])) {
            data[key] = clearObject(data[key]);
          }
          if (method === 'put' && data[key] === '') {
            data[key] = ' ';
          }
          if (
            data[key] === undefined ||
            _.isNull(data[key]) ||
            data[key] === '' ||
            data[key] === '' ||
            (isObject(data[key]) && _.isEmpty(data[key]))
          )
            delete data[key];
          if (data[key] === 'true' || data[key] === 'false') {
            data[key] = data[key] === 'true';
          }
        }
      });
    }
    return data;
  }
};

export const onSuccessRequest = function (config) {
  // Do something before request is sent
  // if (config.method === 'post' || config.method === 'put') {
  //   config.data = clearObject(config.data, config.method);
  // }

  if (config && config.join) {
    const index = config.url.indexOf('?');
    if (index === -1) {
      config.url = config.url + '?'
    }
    config.url += qs.stringify({join: config.join}) + '&';
  }
  if (config && config.filters) {
    /*if (config.url.indexOf('join') !== -1) {
      config.url = config.url + '&';
    }*/
    let filters = _.cloneDeep(config.filters);
    filters = clearObject(filters);
    const index = config.url.indexOf('?');
    if (index === -1) config.url = config.url + '?';

    config.url += Object.keys(filters).map(key => {
      let value = '';
      let isDate = false;
      let keys = Object.keys(filters[key]);
      keys.forEach((innerKey, index) => {
        const detectedValue = _.cloneDeep(filters[key][innerKey]);
        isDate = moment(detectedValue).isValid();
        value += keys[index] + '||' + filters[key][innerKey];
        value += keys.length > 1 && index !== keys.length - 1 ? '*' : '';
      });
      var txt = encodeURI(qs.stringify({...value}));
      if (isDate) {
        let arr = [];
        value.split('*').forEach(x => {
          arr = [...arr, `${key}||${x}`];
        });
        return qs.stringify({filter: arr});
      } else {
        txt = value.replace(/:/gi, '||');
        txt = txt.replace(/"/gi, '');
        return qs.stringify({filter: `${key}||${txt}`});
      }
    }).join('&');
  }
  if (config && config.pagination) {
    const index = config.url.indexOf('?');
    if (index === -1) config.url = config.url + '?';
    const lastIndex = config.url.lastIndexOf('&');
    if (lastIndex !== config.url.length - 1 && index !== config.url.length - 1)
      config.url = config.url + '&';
    let paginationObject = {
      page: config.pagination.page ? config.pagination.page : null,
      limit: config.pagination.rowsPerPage
        ? config.pagination.rowsPerPage
        : null,
    };
    paginationObject = _.cloneDeep(paginationObject);
    paginationObject = clearObject(paginationObject);
    config.url += qs.stringify(paginationObject);
  }
  if (config && config.q) {
    config.url = config.url.indexOf('?') === -1 ? '?' : '&';
    config.url += qs.stringify({q: config.q});
  }
  if (config && config.customParams && !_.isEmpty(config.customParams)) {
    const index = config.url.indexOf('?');
    if (index === -1) {
      config.url = config.url + '?'
    } else {
      config.url = config.url + '&'
    }
    config.url += qs.stringify(config.customParams);
  }
  config.url = config.url.replace(/&&/gi, '&');
  let isLastChar = config.url.slice(config.url.length - 1) === '&' || config.url.slice(config.url.length - 1) === '?';
  if (isLastChar) {
    config.url = config.url.slice(0, config.url.length - 1);
  }
  return config;
};

export const onFailedRequest = function (error) {
  // Do something with request error
  return Promise.reject(error);
};
