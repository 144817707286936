import { LOGIN_USER, LOGOUT_USER, SET_LOADING } from "./types";
import { NotificationManager } from "react-notifications";
import api from "../api/auth";
import { navigate } from "gatsby";
import axios from "axios";
import { API_URL } from "gatsby-env-variables";
import instance from "../api/instance";

export const login = (data) => (dispatch) => {
  dispatch({ type: SET_LOADING, payload: true });
  return api
    .signIn(data)
    .then((res) => {
      if (res) {
        let user = res.user;
        user.accessToken = res.token;
        dispatch(getCurrentUser(user));
        dispatch({ type: LOGIN_USER, payload: res.user });
        NotificationManager.success(
          "You have logged in successfully",
          "",
          3000
        );
        navigate("/app");
        return Promise.resolve(res);
      } else
        NotificationManager.error("Oops, Something went wrong !", "", 3000);
    })
    .catch((error) => {
      NotificationManager.error("Invalid Email or password", "", 3000);
      return Promise.reject(error);
    })
    .finally(() => dispatch({ type: SET_LOADING, payload: false }));
};

export const logoutUser = () => (dispatch) => {
  dispatch({ type: LOGOUT_USER });
  localStorage.removeItem("originall-fii-user");
  navigate("/", { replace: true });
  NotificationManager.success(
      "You have logged out successfully",
      "",
      1000
  );
};

export const getCurrentUser = (user) => (dispatch) => {
  localStorage.setItem("originall-fii-user", JSON.stringify(user));
  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${user.accessToken}`;
  return axios
    .get(`${API_URL}users/me`, {
      headers: { Authorization: `Bearer ${user.accessToken}` },
    })
    .then((res) => {
      dispatch({
        type: LOGIN_USER,
        payload: { ...res.data.user, accessToken: user.accessToken },
      });
      return Promise.resolve(res.data.user);
    })
    .catch(() => {
      dispatch(logoutUser());
      NotificationManager.error(
        "Oops, Session expired please sign in again!",
        "",
        3000
      );
    });
};
