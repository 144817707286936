import axios from 'axios';
import { onSuccessResponse, onFailedResponse } from './onResponseInterceptor';
import { API_URL } from "gatsby-env-variables"

export const authInstance = axios.create({
  baseURL: API_URL,
});

authInstance.interceptors.response.use(onSuccessResponse, onFailedResponse);

delete authInstance.defaults.headers.common.Authorization;

export default {
  signIn: data => authInstance.post(`auth/signin`, data),
}

